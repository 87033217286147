<template>
  <div>
    <Header class="shadow" />
    <div class="home-page page-wrap">
      <div class="pratilipi-list-container">
        <div
          class="banner"
          style="color: linear-gradient(to bottom, #313c33, #161c17)"
        >
          <Banners
            v-if="this.getHomePageLoadingState === 'LOADING_SUCCESS'"
            :banners="getHomePageBanners"
          />
        </div>
      </div>

      <div class="container">
        <div class="series-details-wrap">
          <div class="widgets">
            <div
              v-for="(eachWidget, index) in this.getHomePageSections"
              :key="eachWidget.listPageUrl"
              class="section"
            >
              <!-- <a @click="triggerClickPublishEvent"
                                                      style="float: right; margin-right: 150px;"

                                                      href="/#/login"
                                                      class="myButton"
                                                      ><img
                                                            v-if="index === 2"
                                                            style="max-width:70%;"
                                                            src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/poster.png"
                                                          /></a
                                                      > -->

              <a
                onclick="return triggerClickPublishEvent();"
                href="/login "
                style="background-color: #050b06"
                class="row h-100 h-100 justify-content-center align-items-center"
              >
                <img
                  class="banner-image"
                  v-if="index === 4"
                  src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/poster.png"
                />
              </a>

              <div v-if="index === 9" class="Container calender">
                <div class="row">
                  <div class="col-md-6 col-0">
                    <div class="Upcoming-Comic-Release">
                      Upcoming Comic Releases
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="">
                      <div style="height: 30px">
                        <div
                          v-for="(eachWidget, index) in getCalender"
                          :key="index"
                          style="color: #ffff"
                        >
                          <button
                            class="tablink"
                            @click="changeList(eachWidget.displayTitle, index)"
                            id="defaultOpen"
                            :class="{ active: activeBtn === index }"
                          >
                            {{ eachWidget.displayTitle }}
                          </button>
                        </div>
                      </div>
                      <div
                        v-for="(eachWidget, index) in getCarddata"
                        :key="index"
                        style="color: #ffff"
                      >
                        <div
                          v-if="index < 3"
                          class="col-md-4 col-12"
                          style="width: 200px"
                        >
                          <div
                            class="card mb-6"
                            style="background-color: #050b06; width: 200px"
                          >
                            <div class="row g-0">
                              <div class="col-md-6 col-6 w-100 h-100">
                                <router-link
                                  :to="{
                                    name: 'home-series',
                                    params: { slug: eachWidget.id },
                                  }"
                                  @click.native="
                                    triggerClickCalenderEpisodeEvent(
                                      eachWidget.id
                                    )
                                  "
                                >
                                  <img
                                    class="w-100 h-100"
                                    style="border-radius: 4px"
                                    :src="eachWidget.attributes.coverImageUrl"
                                    alt="..."
                                  />
                                </router-link>
                              </div>
                              <div class="col-md-6 col-6">
                                <p
                                  class="card-text text-start"
                                  style="
                                    text-align: left;
                                    color: #fff;
                                    margin-bottom: 0px;
                                    margin-top: 17px;
                                  "
                                >
                                  {{ eachWidget.attributes.displayTitle }}
                                </p>
                                <p
                                  class="card-text text-episode"
                                  style="
                                    text-align: left;
                                    color: #fff;
                                    margin-bottom: 0px;
                                  "
                                >
                                  <small class="text-muted">
                                    #{{
                                      eachWidget.attributes.displayCategory
                                    }}</small
                                  >
                                </p>

                                <p
                                  class="card-text text-start"
                                  style="text-align: left; margin-bottom: 0px"
                                >
                                  <small class="text-muted"
                                    >{{
                                      eachWidget.attributes.comicSocial.likes
                                    }}
                                    likes</small
                                  >
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <PratilipiListComponent
                v-if="eachWidget.pratilipiList"
                :pratilipi-list="eachWidget.pratilipiList"
                :title="eachWidget.title"
                :list-page-url="eachWidget.listPageUrl"
                :position="index"
                :screen-name="'HOME'"
              />
            </div>
          </div>
        </div>

        <a
          @click="triggerClickDownloadEvent"
          href="https://get.pratilipicomics.com/4u9FS2EWJgb"
          style="background-color: #050b06"
          class="row h-100 h-100 justify-content-center align-items-center"
        >
          <img
            v-if="getHomePageLoadingState === 'LOADING_SUCCESS'"
            class="download-banner"
            style="max-width: 100%"
            src="https://s3.ap-south-1.amazonaws.com/pratilipi.comic.assets/website/img/download-app-banner-hindi.jpg"
          />
        </a>
        <div
          v-if="getHomePageLoadingState === 'LOADING_SUCCESS'"
          class="desktop-only"
        >
          <div class="row" style="margin-top: 5%">
            <span class="pratilipi-text">Contact Us</span>
          </div>
          <div class="row" style="margin-top: 2%">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <div class="th-5th-Floor-Son">
                Email: contact@pratilipi.com Phone: 080 41710149 4th & 5th
                Floor, Sona Tower, Krishna Nagar Industrial Area, Hosur Main
                Road,Bengaluru, Karnataka 560029 CIN: U72200KA2015PTC079230
              </div>
            </div>
            <div class="col-md-3"></div>
          </div>
          <div class="row" style="margin-top: 2%">
            <span style="width: 100%; text-align: center" class="footer-text">
              <router-link
                :to="{ name: 'PrivacyPolicy' }"
                @click="triggerClickPrivacyEvent"
              >
                <a>Privacy Policy</a></router-link
              >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; |
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <router-link
                :to="{
                  name: 'TermOfService',
                }"
                @click.native="triggerClickTermServicesEvent"
              >
                <a> Terms of Service</a></router-link
              >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; |
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; © 2017 Nasadiya Tech. Pvt.
              Ltd.
            </span>
          </div>
        </div>
        <div
          v-if="getHomePageLoadingState === 'LOADING_SUCCESS'"
          class="row "
          style="margin-top: 5%; text-align: left; font-size: 15px"
        >
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <span
              class="pratilipi-text"
              style="text-align: left; font-size: 17px"
              >Contact Us</span
            >
          </div>
        </div>
        <div
          v-if="getHomePageLoadingState === 'LOADING_SUCCESS'"
          class="row"
          style="margin-top: 5%"
        >
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <div
              class="th-5th-Floor-Son"
              style="text-align: left; font-size: 17px"
            >
              Email: contact@pratilipi.com Phone: 080 41710149 4th & 5th Floor,
              Sona Tower, Krishna Nagar Industrial Area, Hosur Main
              Road,Bengaluru, Karnataka 560029 CIN: U72200KA2015PTC079230
            </div>
          </div>
          <div class="col-md-3"></div>
        </div>
        <div
          class="row"
          style="
            margin-top: 2%;
            text-align: center;
            height: 20px;
            margin: 15px 26px 20px 0px;
            font-family: NotoSans;
            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #adadad;
          "
        >
          <span
            class="footer-text"
            style="width: 100%; text-align: center; font-size: 14px"
          >
          <router-link
                :to="{ name: 'PrivacyPolicy' }"
                @click="triggerClickPrivacyEvent"
              >
                <a>Privacy Policy</a></router-link
              >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; |
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <router-link
                :to="{
                  name: 'TermOfService',
                }"
                @click.native="triggerClickTermServicesEvent"
              >
                <a> Terms of Service</a></router-link
              >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; |
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; © 2017 Nasadiya Tech. Pvt.
              Ltd.
          </span>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "bootstrap/dist/css/bootstrap.css"

import Banners from "@/components/Banners.vue";
import PratilipiListComponent from "@/components/PratilipiList.vue";
import Header from "../components/HeaderV2.vue";
import mixins from "@/mixins";
import Spinner from "@/components/Spinner.vue";
export default {
  name: "Home",
  components: {
    Banners,
    Header,
    PratilipiListComponent,
    Spinner,
  },
  mixins: [mixins],
  data() {
    return {
      getCalender: [],
      getCarddata: [],
      day: "",
      activeBtn: 0,

      backupCall: true,
      slideSection: false,
      currentLocale: "hi",
      language_choice: "ENGLISH",
    };
  },
  computed: {
    ...mapGetters("homepage", [
      "getHomePageSections",
      "getHomePageLoadingState",
      "getHomePageSectionsCursor",
      "getHomePageBanners",
      "getHomePageCalender",
    ]),
  },
  beforeRouteLeave(to, from, next) {
    this.clearStore();
    next();
  },
  watch: {
    getHomePageCalender(state) {
      if (this.$store.state.homepage.calender) {
        this.getCalender = this.$store.state.homepage.calender.data;
        this.getCarddata = this.$store.state.homepage.calender.data[0].list
          ? this.$store.state.homepage.calender.data[0].list
          : null;
      }
    },
  },
  created() {
    this.isCreated = true;
    this.backupCall = true;
    let lang_min;
    if (typeof lang_min === "undefined") {
      this.language_choice = "HINDI";
    }

    this.getListOfSections({
      limit: 3,
      language: this.language_choice,
      bucketId: 10,
    });
    this.currentLocale = this.getLanguageCode("HINDI");

    this.triggerAnanlyticsEvent(`LANDED_HOME_PAGE`, "CONTROL", {
      utm_source: this.getUrlVars()["utm_source"],
      utm_medium: this.getUrlVars()["utm_medium"],
      utm_campaign: this.getUrlVars()["utm_campaign"],
      LANGUAGE: "HINDI",
    });
  },
  mounted() {},
  destroyed() {},
  methods: {
    changeList(Weekday, index) {
      this.day = Weekday;
      this.activeBtn = index;
      this.getCarddata = this.getCalender
        .filter((days) => days.displayTitle == Weekday)
        .map((day) => {
          return day.list;
        })[0];
    },

    ...mapActions("homepage", [
      "getListOfSections",
      "getMoreListOfSections",
      "clearStore",
    ]),
    ...mapActions("seriespage", ["fetchSeriesSlugById"]),
    triggerClickPrivacyEvent() {
      this.triggerAnanlyticsEvent(`CLICK_PRIVACY_POLICY`, "CONTROL", {
        LANGUAGE: "HINDI",
      });
    },
    triggerClickTermServicesEvent() {
      this.triggerAnanlyticsEvent(`CLICK_TERM_OF_SERVICE`, "CONTROL", {
        LANGUAGE: "HINDI",
      });
    },
    triggerClickPublishEvent() {
      this.triggerAnanlyticsEvent(`CLICK_PUBLISH`, "CONTROL", {
        LOCATION: "Banner",
        LANGUAGE: "HINDI",
      });
    },
    triggerClickDownloadEvent() {
      this.triggerAnanlyticsEvent(`CLICK_DOWNLOAD_APP`, "CONTROL", {
        LOCATION: "HomePage",
        LANGUAGE: "HINDI",
      });
    },
    triggerClickCalenderEpisodeEvent(seriesId) {
      this.triggerAnanlyticsEvent(`CLICK_CALENDER_EPISODE_APP`, "CONTROL", {
        LOCATION: "HomePage",
        seriesId: seriesId,
        LANGUAGE: "HINDI",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.widgets {
  width: 100;
  height: 100;
  padding: 0 0 25px;
  background-color: #050b06;
}

.banner {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #313c33, #161c17);
  @media screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #313c33, #161c17);
  }
}

.series-details-wrap {
  margin-top: 0px;
}

.home-page {
  min-height: 700px;
  background-color: #050b06;
  @media screen and (max-width: 992px) {
  }
  .footer-text {
    font-family: Nunito;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  @media only screen and (max-width: 450px) {
    .mobile-only {
      display: block;
      background-color: #050b06;
    }
    .desktop-only {
      display: none;
      background-color: #050b06;
    }
    .banner .tablet-only {
      display: none;
      background-color: #050b06;
    }
    .carouse-image {
      width: 280px;
      height: 300px;
      background-color: #050b06;
    }
  }
  @media only screen and (min-width: 450px) and (max-width: 1050px) {
    .mobile-only {
      display: none;
      background-color: #050b06;
    }
    .desktop-only {
      display: block;
      background-color: #050b06;
    }
    .tablet-only {
      display: block;
      background-color: #050b06;
    }
  }
  @media only screen and (min-width: 1050px) {
    .mobile-only {
      display: none;
      background-color: #050b06;
    }
    .desktop-only {
      display: block;
      background-color: #050b06;
    }
    .tablet-only {
      display: block;
      background-color: #050b06;
    }
  }
  .pratilipi-text {
    width: 100%;
    text-align: center;
    font-family: Nunito;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .th-5th-Floor-Son {
    width: 100%;
    text-align: center;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    @media only screen and (max-width: 450px) {
      width: 288px;
      height: 85px;
      margin: 8px 1px 15px;
      font-family: NotoSans;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #adadad;
    }
  }
  .tablink {
    background-color: #202521;
    color: #adadad;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: LucidaGrande;
    margin-bottom: 12px;
    @media only screen and (max-width: 450px) {
      padding: 2px;
      width: 14%;
    }
  }
  .tablink:hover {
    background-color: #777;
  }
  /* Style the tab content */
  .tabcontent {
    color: white;
    display: none;
    padding: 50px;
    text-align: center;
  }
}

.Upcoming-Comic-Release {
  width: 325px;
  height: 28px;
  margin: 170px 29.1px 200px 80px;
  font-family: LucidaGrande;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fefefe;
  @media only screen and (max-width: 450px) {
    margin: 0px 1px 14px -36px;
    height: 100%;
    width: 100%;
    color: white;
    font-size: 20px;
  }
}
.active {
  background-color: #352d2d !important;
  color: #ffffff !important;
}
.overlay {
  height: 0px;
  overflow: visible;
  pointer-events: none;
  background: none !important;
}

.pic-container {
  overflow-y: scroll;
}

.banner-image {
  max-width: 70%;
  @media only screen and (max-width: 450px) {
    max-width: 100%;
    margin-bottom: 40px;
  }
}

.download-banner {
  max-width: 70%;
  @media only screen and (max-width: 450px) {
    max-width: 100%;
    height: 180px;
  }
}
.container {
  max-width: 1010px !important;
}

.calender {
  margin-top: 35px;
  height: 380px;
  @media only screen and (max-width: 450px) {
    margin-top: 0px;
    height: 380px;
  }
}
</style>

<style>
html {
  background-color: black;
}
</style>
